import React, { useContext, useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { AGENCY_ID } from "../../constants";
import { SearchContext } from "../../context/SearchContext";
import BackButton from "../BackButton";
import Trip from "../result/Trip";
import axiosCall from "../../hooks/axiosCall";
import ClipLoader from "react-spinners/ClipLoader";
import { DateTime } from "luxon";
import { applyDiscount } from "../../utils/promoLogic";

const Loader = ({ message }) => {
  // @TODO not sure if we need this anymore
  return <p>{message}</p>;
};

const Review = () => {
  const { travelerInfo, selectedTrip, routeType } = useContext(SearchContext);
  const [promoCode, setPromocode] = useState("");
  const [hasRtn, setHasRtn] = useState(false);
  const [rtnClass, setRtnClass] = useState("hidden");
  const [modalOpen, setModalOpen] = useState(true);
  const [responseLoaded] = useState(true);
  const [alertMessage, setAlertmsg] = useState("");
  const [rtnModal] = useState(false);
  const [disableBtn, setDisabledBtn] = useState(false);
  const [rtnInfo, setRtn] = useState({
    rtnNumber: "",
    rtnName: "",
  });
  const [promoList, setPromoList] = useState([]);
  const [details, setDetails] = useState([]);

  const [coupon, setCoupon] = useState(false);

  useEffect(() => {
    async function agencyCall() {
      await axiosCall(`agency/getbyid/${AGENCY_ID}`, "get", null).then(
        (res) => {
          setPromoList(
            res.data?.promos?.filter(
              (promo) => promo.active === true && promo.promo.active === true
            ) || []
          );
        }
      );
    }
    agencyCall();
  }, []);

  useEffect(() => {
    const details = travelerInfo.map((traveler) => {
      const sanitizedTraveler = {
        firstName: traveler.firstName,
        lastName: traveler.lastName,
        email: traveler.email,
        phoneNumber: traveler.phone,
        identification: traveler.passportId,
        dateBirth: DateTime.fromObject(traveler.birthdate).toISODate(),
        country: traveler.country.value,
        departure: {
          frequencyId: !selectedTrip?.ret
            ? selectedTrip.id
            : selectedTrip.depart.id,
          price: parseInt(
            priceSelector(
              traveler.currentFares,
              traveler.ticketClass.value,
              selectedTrip?.ret,
              traveler.memberInfo?.promoId
            )
          ),
          classType: traveler.ticketClass.value === "Regular Class" ? 2 : 1,
          ticketType: ticketTypeSelector(traveler.ageGroup),
          isPromo: traveler.memberInfo?.promoId ? true : false,
          promoId: traveler.memberInfo?.promoId ?? null,
        },

        ...(selectedTrip.ret && {
          returning: {
            frequencyId: selectedTrip.ret.id,
            price:
              traveler.ticketClass.value === "Regular Class"
                ? parseInt(traveler.currentFares.rate_regular_class.returnPrice)
                : parseInt(traveler.currentFares.rate_first_class.returnPrice),
            classType: traveler.ticketClass.value === "Regular Class" ? 2 : 1,
            ticketType: ticketTypeSelector(traveler.ageGroup),
          },
        }),
      };

      return sanitizedTraveler;
    });
    setDetails(details);
  }, [selectedTrip, travelerInfo]);

  const toggleRtn = () => {
    setHasRtn(!hasRtn);
    const rtnClassUpdate = rtnClass === "hidden" ? "" : "hidden";
    setRtnClass(rtnClassUpdate);
  };

  const totalforpay = details.reduce((acc, curr) => {
    if (curr.departure) {
      acc += curr.departure.price;
    }
    if (curr.returning) {
      acc += curr.returning.price;
    }
    return acc;
  }, 0);

  const ticketTypeSelector = (ageGroup) => {
    const tickettypes = {
      Child: 1,
      Adult: 2,
      Senior: 3,
      Infant: 4,
    };
    return tickettypes[ageGroup];
  };

  const priceSelector = (currentFare, ticketClass, returning, promoId) => {
    if (returning) {
      if(promoId) {
        return 0;
      }
      if (ticketClass === "Regular Class") {
        return currentFare.rate_regular_class.departurePrice;
      } else {
        return currentFare.rate_first_class.departurePrice;
      }
    } else {
      if(promoId) {
        return 0;
      }
      if (ticketClass === "Regular Class") {
        return currentFare.rate_regular_class;
      } else {
        return currentFare.rate_first_class;
      }
    }
  };

  const onPayNow = async () => {
    setDisabledBtn(true);
    const submitObject = {
      agencyId: AGENCY_ID,
      ...(rtnInfo.rtnNumber && {
        rtn: rtnInfo.rtnNumber,
        rtnName: rtnInfo.rtnName,
      }),
      paymentType: 5,
      reservationType: routeType === "One Way" ? 1 : 2,
      details,
    };

    const response = await axiosCall(
      "reservation/create/",
      "post",
      submitObject
    );
    const resCode = response.data.reservationId;
    
    if(totalforpay === 0) {
      window.location = process.env.REACT_APP_PAYMENTURL + "success/" + resCode;
    } else {
      window.location = process.env.REACT_APP_PAYMENTURL + resCode;
    }

    
  };

  const onCloseRtn = () => {};

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const rtnUpdate = (e) => {
    const { name, value } = e.target;
    setRtn({ ...rtnInfo, [name]: value });
  };

  const handlePromo = async () => {
    const { updatedDetails, isError, errorMsg } = await applyDiscount(
      details,
      promoCode,
      promoList,
      selectedTrip,
      coupon
    );
    if (isError) {
      setAlertmsg(errorMsg);
      return;
    }
    setDetails(updatedDetails);
    setCoupon(promoCode);
    setAlertmsg("");
  };

  return (
    <div className="vw vw-payment">
      <BackButton link="/guest-info/" text="Edit Traveler Details" />
      <div className="container">
        <div className="payment-container">
          <div className="inner-content">
            <h2 className="is-title">Trip details</h2>
            <h3>
              <span>Your Trip: </span>
              <div className="result clearfix oneway-display">
                <div className="top topPaddingConfirm">
                  <div className="detail txt-center one-way reviewPageDetail">
                    {selectedTrip?.ret ? (
                      <>
                        <Trip tripModel={selectedTrip.depart} />
                        <Trip
                          tripModel={selectedTrip.ret}
                          indexReturn={selectedTrip.ret}
                        />
                      </>
                    ) : (
                      <Trip tripModel={selectedTrip} />
                    )}
                  </div>
                </div>
              </div>
            </h3>

            <p>
              <strong>Travelers:</strong>
            </p>
            <span>
              {travelerInfo[0].firstName} {travelerInfo[0].lastName} {"  "}
            </span>
            {travelerInfo[0].memberInfo?.freeTicket && (
              <span className="badge-secondary">Ticket Gratis Aplicado</span>
            )}
            
            <br />
            <br />

            {travelerInfo.map((traveler, idx) => {
              return idx > 0 ? (
                <span key={idx}>
                  <span>
                    {traveler.firstName} {traveler.lastName}
                  </span>
                  {traveler.memberInfo?.freeTicket && (
                    <span className="badge-secondary">Ticket Gratis Aplicado</span>
                  )}
                  <br />
                </span>
              ) : null;
            })}
            <p className="total">
              <span>Total:</span>

              <span data-cy="total-display">${totalforpay}.00</span>
            </p>
            {travelerInfo.filter((item) => item.memberInfo?.freeTicket).length > 0 && (
              <div>
                <p>
                  * Como parte de nuestro progama de <strong>LEALTAD</strong>, se han otorgado tickets gratis en esta compra
                </p>
              </div>
            )}
            {coupon && (
              <div className="form-group coupon-group">
                <p>
                  Promo code: <b>{coupon}</b> has been applied
                </p>
              </div>
            )}

            <div>
              <h3>
                <strong>
                  <span>ADD PROMO CODE: </span>
                </strong>
              </h3>
              <div className="form-group">
                <span>CODE:</span>
                <input
                  onChange={(evt) => {
                    setPromocode(evt.target.value);
                  }}
                  className="form-control"
                  name="promoCode"
                />
                <button
                  className="btn-blue promocodeBTN"
                  onClick={() => handlePromo()}
                  disabled={coupon}
                >
                  APPLY
                </button>
              </div>
              {alertMessage && (
                <div className="alert alert-danger" role="alert">
                  {alertMessage}
                </div>
              )}
            </div>

            <br />
            <input
              className="rtnCheck"
              name="rtnCheck"
              type="checkbox"
              onClick={() => toggleRtn()}
            />
            <label htmlFor="rtnCheck">Factura con RTN </label>
            <div className={`payWith rtn-container ${rtnClass}`}>
              <div className="form-group">
                <span>RTN:</span>
                <input
                  onChange={(evt) => {
                    rtnUpdate(evt);
                  }}
                  className="form-control rtn"
                  name="rtnNumber"
                  maxLength={14}
                />
              </div>
              <div className="form-group">
                <span>Name:</span>
                <input
                  onChange={(evt) => {
                    rtnUpdate(evt);
                  }}
                  className="form-control"
                  name="rtnName"
                />
              </div>
            </div>

            {!modalOpen && (
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  data-cy="pay-now"
                  className="btn-blue btn-lg btn-block paynowBtn"
                  onClick={() => onPayNow()}
                  disabled={disableBtn}
                >
                  Pay Now
                </button>
                {disableBtn ? (
                  <ClipLoader
                    color={"#004b88"}
                    loading={disableBtn}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            {modalOpen && (
              <p>You must agree to our policies in order to continue</p>
            )}
          </div>
          {!responseLoaded && (
            <Loader message="Contacting Payment Gateway..." />
          )}
          <Modal
            open={rtnModal}
            onClose={onCloseRtn}
            center
            showCloseIcon={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            classNames={{
              modal: "ModalCustomStyles",
              overlay: "OverlayCustomStyles",
            }}
          >
            <p>Please make sure to fill all RTN fields</p>
            <ol>
              <li>RTN Number must have 14 digits with no symbols</li>
              <li>RTN Name cannot be empty</li>
            </ol>
            <br />
            <button className="btn-blue" onClick={() => onCloseRtn()}>
              OK
            </button>
          </Modal>
          <Modal
            open={modalOpen}
            onClose={onCloseModal}
            center
            showCloseIcon={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            classNames={{
              modal: "ModalCustomStyles",
              overlay: "OverlayCustomStyles",
            }}
          >
            <h2>Galaxy Wave Policies</h2>
            <p>
              Please see below for details on our policies. These are strictly
              enforced to ensure a safe and high quality experience for all of
              our passengers!
            </p>
            <ol>
              <li>
                GALAXY WAVE services are subject to inspection by law
                enforcement officials. When making a reservation, you must
                provide a proper photo identification number (ID for Honduran
                residents and Passports for foreign national). You must carry
                your identification document with you on your trip.
              </li>
              <li>
                {" "}
                Honduran Senior Citizens must present their ID to claim their
                tickets. Failing to do so, will require them to pay an adult’s
                regular ticket fair.
              </li>
              <li>
                All Passengers must present their confirmation number. Print
                your eTicket (on white paper) prior to travel and present it to
                check-in counter. If you lose it, just print it again. If the
                reservation was made through a travel agency, the passenger must
                present a voucher from the travel agency.
              </li>
              <li>
                All Passengers must arrive at our terminals one hour before
                departure to check in. Check-in may be done up to 24 hours prior
                to departure. If passengers do not check in one (1) hour prior
                to departure, they may lose their place to other passengers
                purchasing their tickets at the counter.
              </li>
              <li>
                If your travel plans change, the credit for the reservation
                remains valid up to a year from the date of confirmation. You
                may change your date of travel though our reservations services
                by email info@roatanferry.com{" "}
              </li>
              <li>
                Refunds to cancel unwanted travel or weather conditions are not
                available.
              </li>
              <li>
                Three bags, up to 50 lb and 28 x 22 x 14 inches each, are
                allowed per passenger.
              </li>
              <li>
                Smoking is prohibited in all stations and on all GALAXY WAVE
                services.
              </li>
              <li>
                Firearms are not permitted in checked bags or on carry-on due to
                local law of not firearms permitted on the Island.
              </li>
              <li>
                Minors under 18 years old traveling alone must go in first class
                and a family member must fill out the authorization in any of
                our terminals.
              </li>
              <br />
              <button
                data-cy="close-modal"
                className="btn-blue"
                onClick={() => onCloseModal()}
              >
                I AGREE
              </button>
            </ol>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Review;
