import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { travelerInfoDefaults } from "../../helpers/traveler/travelerInfoDefaults";
import { SearchContext } from "../../context/SearchContext";

const TripRightColumn = ({ selectedTripModel }) => {
  const { setShowGuestInfo, setSelectedTrip, setTravelerInfo, travelerCount } =
    useContext(SearchContext);
  const history = useNavigate();

  const onSelectTrip = () => {
    setShowGuestInfo(true);
    const newTravelers = [...Array(parseInt(travelerCount))].map(
      () => travelerInfoDefaults
    );

    setSelectedTrip(selectedTripModel);
    setTravelerInfo(newTravelers);

    history("/guest-info");
  };

  const SelectButton = () => {
    return (
      <div className="price txt-center">
        <div>
          <button
            onClick={() => onSelectTrip()}
            data-cy="select-trip"
            className="btn-blue btn-block btn-select"
          >
            Select
          </button>
        </div>
      </div>
    );
  };

  const renderSelect = () => {
    return <SelectButton />;
  };

  return renderSelect();
};

TripRightColumn.propTypes = {
  selectedTripModel: PropTypes.object.isRequired,
};

export default TripRightColumn;
