import { useContext } from 'react';
import { SearchContext } from '../../context/SearchContext';

const TotalDisplay = () => {
	const { travelerInfo } = useContext(SearchContext);

	const totalToPay = travelerInfo.reduce(
		(partialSum, item) => partialSum + item.cost,
		0
	);

	return (
		<div className="price txt-center">
			<div>
				<img src="/img/search/ferry-icon.gif" alt="" />
				<p data-cy="total-display">
					${totalToPay}
					<small>Total</small>
				</p>
			</div>
		</div>
	);
};

export default TotalDisplay;
