import React, { useContext } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { SearchContext } from "../../context/SearchContext";
import { TICKET_CLASS_OPTIONS, SELECT_COUNTRIES } from "../../constants";
import { days, months, years } from "../../utils/envs";

const Traveler = ({ idx }) => {
  const Years = years().reverse();

  const {
    travelerInfo,
    travelerUpdateHandler,
    setTravelerInfo,
    travelerCount,
    setTravelerCount,
  } = useContext(SearchContext);

  const removeMe = () => {
    const travelerInfoUpdate = JSON.parse(JSON.stringify(travelerInfo));
    travelerInfoUpdate.splice(idx, 1);
    setTravelerInfo(travelerInfoUpdate);
    setTravelerCount(travelerCount - 1);
  };

  const traveler = travelerInfo[idx];
  traveler.months = months[0];
  traveler.days = days[0];
  traveler.years = Years[0];

  if (!traveler) return false;
  return (
    <div data-cy="traveler-component" className="traveller clearfix">
      <h5>
        <i className="icon-user2" /> Traveler {idx + 1} -{" "}
        <strong className="title-blue">${traveler.cost}</strong>{" "}
        <strong className="rate-info green">
          {" "}
          <small>
            {traveler.ageGroup} - {traveler.ticketClass.value}
          </small>
        </strong>
        {idx === 0 && (
          <p className="contactInfo">
            ( This traveler will be used as contact info )
          </p>
        )}
        {idx > 0 && traveler.cost === 0 && !traveler.boardingPassRequired && (
          <>
            <button
              onClick={() => {
                removeMe();
              }}
              data-cy="btn-remove"
              className="btn-red btn-xs btnGuestContinue float-right"
            >
              Ok, Remove
            </button>
            <p
              data-cy="boarding-pass-age-text"
              className="contactInfo boardingPassAgeRequirementNotice float-right boarding-pass-txt"
            >
              Travelers under the age of 2 do not need boarding passes
            </p>
          </>
        )}
      </h5>

      <div className="form-group">
        <label htmlFor="">
          First Name <sup>*</sup>
        </label>
        <input
          data-cy="firstName"
          type="text"
          required
          name="firstName"
          value={traveler.firstName}
          onChange={(evt) => travelerUpdateHandler({ evt, idx })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="">
          Last Name <sup>*</sup>
        </label>
        <input
          data-cy="lastName"
          type="text"
          required
          name="lastName"
          value={traveler.lastName}
          onChange={(evt) => travelerUpdateHandler({ evt, idx })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="">
          Date of Birth <sup>*</sup>
        </label>
        <div className="datepick-fields">
          <Select
            className="datefield"
            name="month"
            options={months}
            defaultValue={traveler.months}
            onChange={(evt) =>
              travelerUpdateHandler({
                evt,
                idx,
                inputType: "datetime",
                inputName: "month",
              })
            }
          />
          <Select
            className="datefield"
            options={days}
            name="days"
            defaultValue={traveler.days}
            onChange={(evt) =>
              travelerUpdateHandler({
                evt,
                idx,
                inputType: "datetime",
                inputName: "day",
              })
            }
          />
          <Select
            className="datefield"
            options={Years}
            name="years"
            defaultValue={traveler.years}
            onChange={(evt) =>
              travelerUpdateHandler({
                evt,
                idx,
                inputType: "datetime",
                inputName: "year",
              })
            }
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="">
          Passport / ID # <sup>*</sup>
        </label>
        <input
          data-cy="passportId"
          type="text"
          required
          name="passportId"
          value={traveler.passportId}
          onChange={(evt) => travelerUpdateHandler({ evt, idx })}
        />
      </div>
      {idx === 0 && (
        <div className="form-group">
          <label htmlFor="">
            Email: <sup>*</sup>
          </label>
          <input
            data-cy="email"
            type="email"
            required
            name="email"
            value={traveler.email}
            onChange={(evt) => travelerUpdateHandler({ evt, idx })}
          />
        </div>
      )}
      {idx === 0 && (
        <div className="form-group">
          <label htmlFor="">
            Phone: <sup>*</sup>
          </label>
          <input
            data-cy="phone"
            type="text"
            required
            name="phone"
            value={traveler.phone}
            onChange={(evt) => travelerUpdateHandler({ evt, idx })}
          />
        </div>
      )}
      <div className="form-group">
        <label htmlFor="">
          Fare<sup>*</sup>
        </label>
        <Select
          value={traveler.ticketClass}
          options={TICKET_CLASS_OPTIONS}
          onChange={(evt) =>
            travelerUpdateHandler({
              evt,
              idx,
              inputType: "select",
              inputName: "ticketClass",
            })
          }
          searchable={false}
          clearable={false}
        />
      </div>
      <div className="form-group">
        <label htmlFor="">
          Country<sup>*</sup>
        </label>
        <Select
          value={traveler.country}
          options={SELECT_COUNTRIES}
          onChange={(evt) =>
            travelerUpdateHandler({
              evt,
              idx,
              inputType: "select",
              inputName: "country",
            })
          }
          searchable={false}
          clearable={false}
        />
      </div>
    </div>
  );
};

Traveler.propTypes = {
  idx: PropTypes.number.isRequired,
};

export default Traveler;
