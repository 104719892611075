import { TICKET_CLASS_REGULAR } from "../../constants";

export const travelerInfoDefaults = {
  firstName: "",
  lastName: "",
  birthdate: { month: "1", day: "1", year: "2023" },
  boardingPassRequired: true,
  passportId: "",
  email: "",
  phone: "",
  cost: 0,
  ageGroup: "",
  ticketClass: {
    value: TICKET_CLASS_REGULAR,
    label: TICKET_CLASS_REGULAR,
  },
  country: {
    value: "Honduras",
    label: "Honduras",
  },
  currentFares: {
    rateType: "",
    rate_first_class: 0,
    rate_regular_class: 0,
  },
};
