import axios from "axios";

const axiosCall = async (url, action, data) => {
  try {
    const response = await axios({
      method: action,
      url: process.env.REACT_APP_API_TAP + url,
      data: data,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export default axiosCall;
