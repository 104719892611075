const reactSelectStyle = {
  container: (provided) => ({
    ...provided,
    display: 'table',
    width: '100%',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none'
  }),
  control: (provided) => ({
    ...provided,
    border: '2px solid #757575',
    borderRadius: '0',
    minHeight: '1px',
    height: '39px'
  }),
  input: (provided) => ({
    ...provided,
    minHeight: '1px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: '1px',
    height: '23px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: '1px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '1px',
    height: '39px',
    paddingTop: '0',
    paddingBottom: '0'
  }),
  singleValue: (provided) => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px'
  })
}

export default reactSelectStyle
