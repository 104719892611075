import React from 'react'

const Header = () => {
  return (
    <>
      <div>
        <input type='checkbox' id='menu-state' className='hide mm-trigger' />
        <header>
          <div className='stickyBar'> </div>

          <div className='container-fluid-header blueHead'>
            <div className='headerMain'>
              <div className='logo'>
                <a href='/'>
                  <img
                    src='https://www.roatanferry.com/img/logowhite.svg'
                    alt='Logo Galaxy Wave'
                    with='100%'
                  />
                </a>
              </div>
            </div>
          </div>

          <div className='btnContainer'>
            <div className='miniMenu'>
              <a href='https://www.roatanferry.com/about-us/'>About Us</a>
              <a href='https://www.roatanferry.com/faqs/'>Travel Faq's</a>
              <a href='https://www.roatanferry.com/contact/'>Contact Us</a>
            </div>

            <a href='https://roatanferry.com/'>
              <div className='BookBtnTop'>Back Home</div>
            </a>
          </div>
        </header>
      </div>
    </>
  )
}

export default Header
