import React from 'react'

const Footer = () => {
  return (
    <>
      <footer>
        <div className='footertop' />
        <div className='footer'>
          <div className='logofooter'>
            <img
              src='https://www.roatanferry.com/img/logowhite.svg'
              alt='Logo Wave'
              width='100%'
            />
          </div>

          <div className='mobileFooter'>
            <a href='/'>Home</a>
            <br />
            <a href='https://www.roatanferry.com/schedule-fares'>
              Departures & Fares
            </a>
            <br />
            <a href='https://www.roatanferry.com/our-terminals'>
              Our Terminals
            </a>
            <br />
            <a href='https://www.roatanferry.com/destination-roatan'>
              Destination Roatan
            </a>
            <br />
            <a href='https://www.roatanferry.com/destination-la-ceiba'>
              Destination La Ceiba
            </a>
            <br />
            <a href='https://www.roatanferry.com/about-us'>About Us</a>
            <br />
            <a href='https://www.roatanferry.com/contact'>Contact Us</a>
            <br /> <br />
            <a href='/#' className='maila'>
              info@roatanferry.com
            </a>
            <br />
            <p className='mailaFollow'>
              Follow us:{' '}
              <a
                href='https://www.facebook.com/GalaxyWaveRoatanFerry'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://www.roatanferry.com/img/icon-facebook.svg'
                  alt='facebook icon'
                />
              </a>
              <a
                href='https://www.instagram.com/roatanferry/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://www.roatanferry.com/img/icon-instagram.svg'
                  alt='instagram icon'
                />
              </a>
              <a
                href='https://twitter.com/FerryGalaxy'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://www.roatanferry.com/img/icon-twitter.svg'
                  alt='twitter icon'
                />
              </a>
            </p>
            <br />
            <br />
          </div>

          <div className='desktopfooter'>
            <div className='footContent'>
              <div className='footCol'>
                <h1>Departures & Fares</h1>

                <a href='https://www.roatanferry.com/schedule-fares'>
                  Departures and Fares
                </a>
                <br />
                <a href='https://www.roatanferry.com/faqs'>
                  Galaxy Wave Policies
                </a>
                <br />
                <a href='https://www.roatanferry.com/faqs'>Travel FAQ's</a>
              </div>

              <div className='footCol'>
                <h1>Travel Information</h1>
                <a href='https://www.roatanferry.com/our-terminals'>
                  Our Terminals
                </a>
                <br />
                <a href='https://www.roatanferry.com/group-travel'>
                  Group Travel
                </a>
                <br />
                <a href='https://www.roatanferry.com/travel-special-needs'>
                  Special Travel Needs
                </a>
                <br />
                <a href='https://www.roatanferry.com/faqs'>FAQ's</a>
              </div>

              <div className='footCol'>
                <h1>Destinations</h1>
                <a href='https://www.roatanferry.com/our-terminals'>
                  Route Map
                </a>
                <br />
                <a href='https://www.roatanferry.com/destination-roatan'>
                  Destination Roatan
                </a>
                <br />
                <a href='https://www.roatanferry.com/destination-la-ceiba'>
                  Destination La Ceiba
                </a>
              </div>

              <div className='footCol'>
                <h1>Cargo</h1>
                <a href='https://www.roatanferry.com/cargo'>Cargo Rates</a>
                <br />
                <a href='https://www.roatanferry.com/cargo'>
                  Pick-up drop-off times
                </a>
              </div>

              <div className='footCol'>
                <h1>Roatan</h1>
                <a href='https://www.roatanferry.com/roatan-hotels'>Hotels</a>
                <br />
                <a href='https://www.roatanferry.com/roatan-attractions'>
                  Attractions
                </a>
                <br />
                <a href='https://www.roatanferry.com/transportation-roatan'>
                  Transportation
                </a>
              </div>

              <div className='footCol'>
                <h1>About Us</h1>
                <a href='https://www.roatanferry.com/about-us'>Our Story</a>
                <br />
                <a href='https://www.roatanferry.com/our-fleet'>Our Fleet</a>
                <br />
                <a href='https://www.roatanferry.com/contact'>Contact Us</a>
                <br /> <br />
                <a href='/#' className='maila'>
                  info@roatanferry.com
                </a>
                <br />
                <p className='mailaFollow'>
                  Follow us:{' '}
                  <a
                    href='https://www.facebook.com/GalaxyWaveRoatanFerry'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src='https://www.roatanferry.com/img/icon-facebook.svg'
                      alt='facebook icon'
                    />
                  </a>
                  <a
                    href='https://www.instagram.com/roatanferry/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src='https://www.roatanferry.com/img/icon-instagram.svg'
                      alt='instagram icon'
                    />
                  </a>
                  <a
                    href='https://twitter.com/FerryGalaxy'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src='https://www.roatanferry.com/img/icon-twitter.svg'
                      alt='twitter icon'
                    />
                  </a>
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
