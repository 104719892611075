import React from 'react'

const HomeStatic = () => {
  return (
    <div className='container vw-home homeTexts'>
      <div className='intro clearfix' />
      <div className='ib'>
        <h2 className='homeInitialMesg'>Select your route and date...</h2>
      </div>
    </div>
  )
}

export default HomeStatic
