import { countries } from './countries';

export const AGENCY_ID = process.env.REACT_APP_AGENCY;

export const ONE_WAY_TRIP_TYPE = 1;
export const ROUND_WAY_TRIP_TYPE = 2;
export const ROUTE_TYPE_ONE_WAY = 'One Way';
export const ROUTE_TYPE_ROUND_TRIP = 'Round Trip';
export const DATE_DISPLAY_FORMAT = 'ddd, MMM D';
export const DATE_SAVE_FORMAT = 'MM-DD-YYYY';
export const MINUTES_BEFORE_BOOKABLE = 30;
export const TICKET_CLASS_REGULAR = 'Regular Class';
export const TICKET_CLASS_FIRST = 'First Class';
export const TICKET_CLASS_OPTIONS = [
	{ label: TICKET_CLASS_REGULAR, value: TICKET_CLASS_REGULAR },
	{ label: TICKET_CLASS_FIRST, value: TICKET_CLASS_FIRST },
];
export const DEFAULT_AGE_GROUP = 'Children (0 - 4)';

export const SELECT_COUNTRIES = countries.map((c) => {
	const countryName = c.name;
	return {
		value: countryName,
		label: countryName,
	};
});
