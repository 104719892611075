const validationErrors = {
  emailBlank: 'Please enter an email address for Traveler 1',
  emailInvalid: 'Please enter a valid email address',
  passportBlank: 'Please enter all traveler Passport / Ids (Ages 5 and up)',
  firstNameBlank: 'Please enter all traveler first names (Ages 5 and up)',
  lastNameBlank: 'Please enter all traveler last names (Ages 5 and up)',
  phoneBlank: 'Please enter a valid phone number for Traveler 1'
}

export default validationErrors
