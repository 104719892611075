import React from 'react'
import { Link } from 'react-router-dom'

const BackButton = ({ link, text }) => (
  <div className='newSearchBar'>
    <div className='container no-padding'>
      <Link to={link}><b><i className='icon-chevron-thin-left' /></b> {text}</Link>
    </div>
  </div>
)

export default BackButton
