import { ROUND_WAY_TRIP_TYPE } from "../utils/envs";
import { DateTime } from "luxon";

const UseAgePoolAndFare = (trip, date) => {
  let tripType;

  if (trip.ret) {
    tripType = ROUND_WAY_TRIP_TYPE;
  }

  const todayDate = DateTime.now();
  const userDate = DateTime.fromFormat(date, "MM-dd-yyyy");
  const newAge = todayDate
    .diff(userDate, ["years", "months", "days"])
    .toObject();

  switch (true) {
    case newAge.years >= 60:
      return {
        rateType: "Senior",
        rate_first_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: (
                  Number(trip?.depart?.rate_senior_first_class) +
                  Number(trip?.ret?.rate_senior_first_class)
                ).toString(),
                departurePrice: trip?.depart?.rate_senior_first_class,
                returnPrice: trip?.ret?.rate_senior_first_class,
              }
            : trip?.rate_senior_first_class,
        rate_regular_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: (
                  Number(trip?.depart?.rate_senior_regular_class) +
                  Number(trip?.ret?.rate_senior_regular_class)
                ).toString(),
                departurePrice: trip?.depart?.rate_senior_regular_class,
                returnPrice: trip?.ret?.rate_senior_regular_class,
              }
            : trip?.rate_senior_regular_class,
      };
    case newAge.years >= 11:
      return {
        rateType: "Adult",
        rate_first_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: (
                  Number(trip?.depart?.rate_adult_first_class) +
                  Number(trip?.ret?.rate_adult_first_class)
                ).toString(),
                departurePrice: trip?.depart?.rate_adult_first_class,
                returnPrice: trip?.ret?.rate_adult_first_class,
              }
            : trip?.rate_adult_first_class,
        rate_regular_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: (
                  Number(trip?.depart?.rate_adult_regular_class) +
                  Number(trip?.ret?.rate_adult_regular_class)
                ).toString(),
                departurePrice: trip?.depart?.rate_adult_regular_class,
                returnPrice: trip?.ret?.rate_adult_regular_class,
              }
            : trip?.rate_adult_regular_class,
      };
    case newAge.years >= 5:
      return {
        rateType: "Child",
        rate_first_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: (
                  Number(trip?.depart?.rate_child_first_class) +
                  Number(trip?.ret?.rate_child_first_class)
                ).toString(),
                departurePrice: trip?.depart?.rate_child_first_class,
                returnPrice: trip?.ret?.rate_child_first_class,
              }
            : trip?.rate_child_first_class,
        rate_regular_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: (
                  Number(trip?.depart?.rate_child_regular_class) +
                  Number(trip?.ret?.rate_child_regular_class)
                ).toString(),
                departurePrice: trip?.depart?.rate_child_regular_class,
                returnPrice: trip?.ret?.rate_child_regular_class,
              }
            : trip?.rate_child_regular_class,
      };
    default:
      return {
        rateType: "Infant",
        rate_first_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: 0,
                departurePrice: 0,
              }
            : 0,
        rate_regular_class:
          tripType === ROUND_WAY_TRIP_TYPE
            ? {
                roundtripPrice: 0,
                departurePrice: 0,
                returnPrice: 0,
              }
            : 0,
      };
  }
};

export default UseAgePoolAndFare;
