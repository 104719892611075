//ROlES
export const ROL_USUARIO = 1;
export const ROL_ADMIN_AGENCIA = 2;
export const ROL_SUPERADMIN = 3;
export const ROL_SALES = 4;
export const ROL_KIOSKO = 5;
export const ROL_SYSADMIN = 6;
export const ROL_OPERATIONS = 7;
export const ROL_ACC = 8;

//PROMOS
export const TYPE_PROMO_TICKET_FREE = 2;
export const TYPE_PROMO_DISCOUNT = 1;
export const BLACK_OUT_DATES = [
  "2023-10-05",
  "2023-10-06",
  "2023-10-07",
  "2023-10-08",
  "2024-03-22",
  "2024-03-23",
  "2024-03-24",
  "2024-03-25",
  "2024-03-26",
  "2024-03-27",
  "2024-03-28",
  "2024-03-29",
  "2024-03-30",
  "2024-03-31",
];

//blackoutdates
export const BLACK_OUT_DATES_NO_MORAZANICA = [
  "2024-03-22",
  "2024-03-23",
  "2024-03-24",
  "2024-03-25",
  "2024-03-26",
  "2024-03-27",
  "2024-03-28",
  "2024-03-29",
  "2024-03-30",
  "2024-03-31",
];

//TYPES
export const ONE_WAY_TRIP_TYPE = 1;
export const ROUND_WAY_TRIP_TYPE = 2;
export const PAYMENT_TYPE_CREDIT = 1;
export const PAYMENT_TYPE_PREPAID = 2;
export const PAYMENT_TYPE_FREE = 3;
export const CLASS_TYPE_FIRST_CLASS = 1;
export const CLASS_TYPE_REGULAR_CLASS = 2;
export const TICKET_TYPE_CHILD = 1;
export const TICKET_TYPE_ADULT = 2;
export const TICKET_TYPE_SENIOR = 3;
export const TICKET_TYPE_INFANT = 4;

//STRING TYPES
export const CLASS_STRING_TYPE_FIRST_CLASS = "First Class";
export const CLASS_STRING_TYPE_REGULAR_CLASS = "Regular Class";
export const FREQUENCY_TYPE_MULTIPLE = "multiple";
export const FREQUENCY_TYPE_SINGLE = "single";

//HTTP ACTIONS
export const HTTP_ACTION_PUT = "PUT";
export const HTTP_ACTION_POST = "POST";
export const HTTP_ACTION_GET = "GET";

//AGE CATEGORIES
export const AGE_CATEGORY_ADULT = "Adult";
export const AGE_CATEGORY_CHILD = "Child";
export const AGE_CATEGORY_INFANT = "Infant";
export const AGE_CATEGORY_SENIOR = "Senior";

export const months = [
  { label: "Jan", value: "1" },
  { label: "Feb", value: "2" },
  { label: "Mar", value: "3" },
  { label: "Apr", value: "4" },
  { label: "May", value: "5" },
  { label: "Jun", value: "6" },
  { label: "Jul", value: "7" },
  { label: "Aug", value: "8" },
  { label: "Sep", value: "9" },
  { label: "Oct", value: "10" },
  { label: "Nov", value: "11" },
  { label: "Dec", value: "12" },
];

export const days = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const years = () => {
  const year = new Date().getFullYear();
  const years = Array.from({ length: 110 }, (v, i) => year - 110 + i + 1);
  return years.map((year) => {
    return { label: year, value: year };
  });
};
