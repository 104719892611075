import React, { useContext, useState } from "react";

import Select from "react-select";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import Modal from "react-responsive-modal";

import {
  ROUTE_TYPE_ONE_WAY,
  ROUTE_TYPE_ROUND_TRIP,
  AGENCY_ID,
  ONE_WAY_TRIP_TYPE,
  ROUND_WAY_TRIP_TYPE,
} from "../../constants";
import { SearchContext } from "../../context/SearchContext";
import "../../sass/components/_reactDateTime.scss";
import "./search.css";
import reactSelectStyle from "../../styles/reactSelectStyles";
import axiosCall from "../../hooks/axiosCall";

const Search = () => {
  const {
    allRoutes,
    selectedRoute,
    setSelectedRoute,
    travelerCount,
    setTravelerCount,
    errorMessage,
    setShowTrips,
    routeType,
    setRouteType,
    departureDate,
    setDepartureDate,
    returnDate,
    setReturnDate,
    setAlltrips,
  } = useContext(SearchContext);
  const [showModal, setShowModal] = useState(false);
  const [postObject] = useState({ agencyId: AGENCY_ID });
  const dateFormatter = (date) =>
    moment(date).format("YYYY/MM/DD").replace("/", "-").replace("/", "-");

  const search = async (e) => {
    e.preventDefault();
    let ports = selectedRoute.split("/");

    let finalObject = {
      ...postObject,
      originPortId: ports[0],
      destinationPortId: ports[1],
      departureDate: dateFormatter(departureDate),
      type: routeType === "One Way" ? ONE_WAY_TRIP_TYPE : ROUND_WAY_TRIP_TYPE,
      returnDate: routeType === "oneway" ? null : dateFormatter(returnDate),
    };

    const response = await axiosCall("search/", "post", finalObject);
    setAlltrips(response.data);
    setShowTrips(true);
  };

  const parsedRoutes = allRoutes.map((route, index) => {
    return {
      value: route.originPortId + "/" + route.destinationPortId,
      label: route.originPort + " → " + route.destinationPort,
    };
  });

  const onRouteTypeClicked = (e, routeType) => {
    e.preventDefault();
    setRouteType(routeType);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  let yesterday = moment().subtract(1, "day");
  let returningDate = moment(departureDate).subtract(1, "day");
  const validDeparture = (current) => current.isAfter(yesterday);
  const validReturning = (current) => moment(current).isAfter(returningDate);

  return (
    <aside id="quickQuote" className="quickQuote">
      <form onSubmit={(e) => search(e)}>
        <div className="type">
          <button
            data-cy="tab-one-way"
            className={routeType === ROUTE_TYPE_ONE_WAY ? "selected" : ""}
            onClick={(e) => {
              onRouteTypeClicked(e, ROUTE_TYPE_ONE_WAY);
            }}
          >
            <img src="/img/qq/single-arrow.png" alt="" />
            One Way
          </button>
          <button
            data-cy="tab-round-trip"
            className={routeType === ROUTE_TYPE_ROUND_TRIP ? "selected" : ""}
            onClick={(e) => {
              onRouteTypeClicked(e, ROUTE_TYPE_ROUND_TRIP);
            }}
          >
            <img src="/img/qq/double-arrow.png" alt="" />
            Round Trip
          </button>
        </div>
        <div className="criteria">
          <div className="form-group xl-size">
            <label htmlFor="route">Route:</label>
            <Select
              styles={reactSelectStyle}
              isSearchable={false}
              name="route"
              searchable={false}
              clearable={false}
              options={parsedRoutes}
              onChange={(e) => setSelectedRoute(e.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">DEPART:</label>
            <Datetime
              inputProps={{
                "data-cy": "depart-date-select",
                className: "datetime",
              }}
              initialValue={new Date()}
              isValidDate={(current) => validDeparture(current)}
              dateFormat="MM/DD/YYYY"
              locale="es-HN"
              timeFormat={false}
              onChange={(current) => setDepartureDate(current)}
              closeOnSelect
            />
          </div>

          <div
            className={routeType === ROUTE_TYPE_ONE_WAY ? "hide" : "form-group"}
          >
            <label htmlFor="">RETURN:</label>
            <Datetime
              dateFormat="MM/DD/YYYY"
              initialValue={new Date()}
              timeFormat={false}
              isValidDate={(current) => validReturning(current)}
              onChange={(current) => setReturnDate(current)}
              closeOnSelect
            />
          </div>
          <div className="form-group travellers-group">
            <label htmlFor="">Travelers:</label>
            <input
              type="number"
              min="1"
              value={travelerCount}
              onChange={(e) => setTravelerCount(e.target.value)}
            />
          </div>
          <input
            data-cy="search"
            type="submit"
            value="Search"
            className="qqSubmitBtn btn-blue"
            disabled={!selectedRoute}
          />
          <p className="contactInfo boardingPassAgeRequirementNotice search-traveler-select-info-txt">
            *Traveler count required for ages 3 and up{" "}
            <i
              onClick={() => setShowModal(true)}
              className="fas fa-info-circle info-btn"
            />
          </p>
        </div>
        {errorMessage && <div className="errMsg">{errorMessage}</div>}
      </form>

      <Modal
        open={showModal}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          modal: "ModalCustomStyles",
          overlay: "OverlayCustomStyles",
        }}
      >
        <h2>Traveler Boarding Passes</h2>
        <p>Travelers 2 years old and younger do not require boarding passes.</p>
        <p>
          If any of your travelers are 2 years old or younger, you may omit them
          from your traveler count.
        </p>
        <ol>
          <br />
          <button
            data-cy="close-modal"
            className="btn-blue"
            onClick={() => onCloseModal()}
          >
            OK
          </button>
        </ol>
      </Modal>
    </aside>
  );
};

export default Search;
