import { useEffect, useState } from "react";
import axiosCall from "./axiosCall";

export const useRouteData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const json = await axiosCall("trip/getallactive", "GET", null);
        json.data = json.data.filter(
          (trip) =>
            trip.originPort !== "Guanaja" && trip.destinationPort !== "Guanaja"
        );
        setData(json.data);
      } catch (e) {
        console.error("error fetching routeData", e);
      }
    };

    fetchData();
  }, []);

  return [data];
};
