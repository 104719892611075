import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SearchContext } from "../../context/SearchContext";
import Search from "../search/Search";
import HomeStatic from "./HomeStatic";
import TopSection from "./TopSection";
import Result from "../result/Result";

const Home = () => {
  const location = useLocation();
  const { showTrips, setShowTrips, showGuestInfo, setShowGuestInfo } =
    useContext(SearchContext);

  useEffect(() => {
    const path = location.pathname;
    // @Todo might move this to a central hook
    if (showTrips && path === "/") {
      setShowTrips(false);
    }
    if (showGuestInfo) {
      if (path.includes("trips") || path.includes("search")) {
        setShowGuestInfo(false);
      }
    }
  }, [location]);

  return (
    <>
      <TopSection showTrips={showTrips}>
        <Search />
        {showTrips && <Result />}
      </TopSection>
      {!showTrips && <HomeStatic />}
    </>
  );
};

export default Home;
