import React, { useContext } from 'react'

import { SearchContext } from '../../context/SearchContext'

const TripFooter = () => {
  const {
    showGuestInfo
  } = useContext(SearchContext)
  return (
    <>
      {!showGuestInfo && (
        <div>
          <div className='hr-divider' />
          <div className='result-footer'>
            <p className='total'><small>Date and Time are subject to change.</small></p>
          </div>
        </div>
      )}
    </>
  )
}

export default TripFooter
