import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./sass/master.scss";

import { SearchProvider } from "./context/SearchContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/home/Home";
import GuestInfo from "./components/guestinfo/GuestInfo";
import Review from "./components/review/Review";

const App = () => {
  return (
    <Router>
      <main id="main" role="main">
        <Header />
        <SearchProvider>
          <Routes>
            <Route path="/order-review" element={<Review />} />
            <Route path="/guest-info" element={<GuestInfo />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </SearchProvider>
        <Footer />
      </main>
    </Router>
  );
};

export default App;
