import React from 'react'

const TopSection = ({ showTrips, children }) => {
  if (showTrips) {
    return (
      <div className='vw vw-search'>
        <div className='top-section' />
        {children}
      </div>
    )
  } else {
    return (
      <div className='vw vw-search'>
        <div className='top-section' />
        {children}
      </div>
    )
  }
}

export default TopSection
