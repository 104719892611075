import React, { useContext } from "react";

import { SearchContext } from "../../context/SearchContext";
import { getCurrentTime } from "../../utils";
import Trip from "./Trip";
import TripRightColumn from "./TripRightColumn";
import TripFooter from "./TripFooter";
import moment from "moment";

const Result = ({ curTime }) => {
  curTime = curTime || getCurrentTime();
  const { availableTrips } = useContext(SearchContext);

  let arrOftrips = [];

  if (availableTrips.return.length) {
    availableTrips.departure.forEach((depart) => {
      availableTrips.return.forEach((ret) => {
        if (
          moment(depart.departure_date).isBefore(moment(ret.departure_date)) &&
          moment(depart.departure_date).isAfter(moment())
        ) {
          arrOftrips.push({ depart, ret });
        }
      });
    });
  } else {
    arrOftrips = availableTrips?.departure
      .map((trip) => {
        if (moment(trip.departure_date).isAfter(moment())) {
          return trip;
        }
        return false;
      })
      .filter(Boolean);
  }

  return (
    <div className="container">
      <div id="results">
        <div data-reactroot="">
          <p className="tripsMessage">
            {availableTrips?.return?.length ? (
              <>Available Trips: {arrOftrips.length}</>
            ) : (
              <>Available Trips: {arrOftrips.length}</>
            )}
          </p>
          {!availableTrips.return.length ? (
            <>
              {arrOftrips?.map((trip, index) => {
                return (
                  <div className="result clearfix oneway-display" key={index}>
                    <div className="top">
                      <div className="detail txt-center one-way">
                        <Trip tripModel={trip} />
                      </div>
                      <TripRightColumn selectedTripModel={trip} />
                    </div>
                    <TripFooter />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              {arrOftrips?.map((trips, indexOne) => {
                return (
                  <div
                    className="result clearfix oneway-display"
                    key={indexOne}
                  >
                    <div className="top">
                      <div className="detail txt-center one-way">
                        <Trip tripModel={trips.depart} />
                        <Trip tripModel={trips.ret} indexReturn={trips.ret} />
                      </div>
                      <TripRightColumn selectedTripModel={trips} />
                    </div>
                    <TripFooter />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Result;
